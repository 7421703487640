import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './AfterHero.scss';
import { graphql } from 'gatsby';
import Grid from '../Grid/Grid';

function AfterHero({ data }) {
  return (
    <section className={classnames('AfterHero', `theme-${data.theme || 'purple'}`, 'container')}>
      <div className="wrapper">
        <Grid data={{ ...data, columns: 3 }} />
      </div>
    </section>
  );
}

AfterHero.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...Grid.type.propTypes.data,
    theme: PropTypes.oneOf(['purple', 'green']),
  }).isRequired,
});

export default memo(AfterHero);

export const fragments = graphql`
  fragment AfterHero on WpPage_Flexiblelayout_FlexibleChildren_AfterHero {
    description
    theme
    title
    gridType
    gridItems {
      bio
      description
      name
      title
      link {
        url
        title
        target
      }
      icon {
        ...FluidImage
      }
      logo {
        ...FluidImage
      }
      photo {
        ...FluidImage
      }
    }
    name
    id
    jumpToLinkTitle
  }

  fragment AfterHero_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_AfterHero {
    description
    theme
    title
    gridType
    gridItems {
      bio
      description
      name
      title
      link {
        url
        title
        target
      }
      icon {
        ...FluidImage
      }
      logo {
        ...FluidImage
      }
      photo {
        ...FluidImage
      }
    }
    name
    id
    jumpToLinkTitle
  }
`;
